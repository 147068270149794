import revive_payload_client_c6x5swo9p4 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.14_eslint@9.13.0_jiti@2.3.3__ioredis@5.4._vritcropf6kotdykrff3kkpz44/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Aj7jUxElWi from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.14_eslint@9.13.0_jiti@2.3.3__ioredis@5.4._vritcropf6kotdykrff3kkpz44/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5mP4VMNI7P from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.14_eslint@9.13.0_jiti@2.3.3__ioredis@5.4._vritcropf6kotdykrff3kkpz44/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_PMeT7aktFh from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.14_eslint@9.13.0_jiti@2.3.3__ioredis@5.4._vritcropf6kotdykrff3kkpz44/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_932Z5KVvaI from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.14_eslint@9.13.0_jiti@2.3.3__ioredis@5.4._vritcropf6kotdykrff3kkpz44/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_0xNkfRcusB from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.14_eslint@9.13.0_jiti@2.3.3__ioredis@5.4._vritcropf6kotdykrff3kkpz44/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_OtcUzZTAVB from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.14_eslint@9.13.0_jiti@2.3.3__ioredis@5.4._vritcropf6kotdykrff3kkpz44/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_EzIQGIe80m from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/.nuxt/components.plugin.mjs";
import prefetch_client_nymPvPCUlF from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.14_eslint@9.13.0_jiti@2.3.3__ioredis@5.4._vritcropf6kotdykrff3kkpz44/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wlAbJRr3zS from "/opt/render/project/src/node_modules/.pnpm/@nuxt+icon@1.5.6_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@20.16.14_sass@1.80.3_ter_zmb2ia3hyljabfgaro52n5meou/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/opt/render/project/src/.nuxt/primevue-plugin.mjs";
import plugin_client_vxscHAFxQs from "/opt/render/project/src/node_modules/.pnpm/@primevue+nuxt-module@4.1.1_@babel+parser@7.25.8_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_wy0B721ODc from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_1A7GuXBFF4 from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.11_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import plugin_mDg8dtQcI1 from "/opt/render/project/src/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typesc_ljwbealukr7tqg5b2kzl4sq2g4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import apollo_1ILFkzpYSr from "/opt/render/project/src/plugins/apollo.ts";
import auto_animate_uTpJuTGsMC from "/opt/render/project/src/plugins/auto-animate.ts";
import error_handler_kEP5FliEXj from "/opt/render/project/src/plugins/error-handler.ts";
import maska_UHaKf2z1iQ from "/opt/render/project/src/plugins/maska.ts";
import notifications_Wskzy5ZO71 from "/opt/render/project/src/plugins/notifications.ts";
export default [
  revive_payload_client_c6x5swo9p4,
  unhead_Aj7jUxElWi,
  router_5mP4VMNI7P,
  payload_client_PMeT7aktFh,
  navigation_repaint_client_932Z5KVvaI,
  check_outdated_build_client_0xNkfRcusB,
  chunk_reload_client_OtcUzZTAVB,
  plugin_vue3_EzIQGIe80m,
  components_plugin_KR1HBZs4kY,
  prefetch_client_nymPvPCUlF,
  plugin_wlAbJRr3zS,
  primevue_plugin_egKpok8Auk,
  plugin_client_vxscHAFxQs,
  plugin_wy0B721ODc,
  plugin_1A7GuXBFF4,
  plugin_mDg8dtQcI1,
  apollo_1ILFkzpYSr,
  auto_animate_uTpJuTGsMC,
  error_handler_kEP5FliEXj,
  maska_UHaKf2z1iQ,
  notifications_Wskzy5ZO71
]