import validate from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.14_eslint@9.13.0_jiti@2.3.3__ioredis@5.4._vritcropf6kotdykrff3kkpz44/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.14_eslint@9.13.0_jiti@2.3.3__ioredis@5.4._vritcropf6kotdykrff3kkpz44/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/render/project/src/middleware/auth.ts"),
  form: () => import("/opt/render/project/src/middleware/form.ts"),
  submission: () => import("/opt/render/project/src/middleware/submission.ts")
}